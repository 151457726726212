import { Array, Literal, Number, Record, String, Union } from 'runtypes';

// Pure typescript type NormTimeType will eventually be replaced
import { NormTimeCategory, NormTimeType as INormTimeType } from '../types/norm-time-types';
import {
    CreateNormTimeTypeRequest,
    NormTimeType,
    NormTimeTypeCreateResponse,
    NormTimeTypeGroup,
    NormTimeTypeGroupDetails,
    NormTimeTypeGroupReservationsNormWorker,
    NormTimeTypeGroupReservationsOverview,
    NormTimeTypes,
} from './norm-time-types.type';

import { getJwt } from '../jwt';
import { request, requestWithRuntype, requestWithType } from './request';

export async function fetchAllTimeCategoriesWithTypes(normPeriodsSNO: number): Promise<NormTimeCategory[]> {
    const ResponseType = Array(
        Record({
            normTimeCategoriesSNO: Number,
            name: String,
            nameShort: String,
            type: String,
            isAutoApproved: Number,
            timeTypes: Array(
                Record({
                    normTimeTypesSNO: Number,
                    type: String,
                    name: String,
                    nameShort: String,
                    colorCodeBackground: String,
                    colorCodeText: String,
                    timeUnit: Union(Literal('days'), Literal('hours')),
                    isCommon: Number,
                    maskInOverviews: Number,
                    showInPlanning: Number,
                    showInPlanningAssistant: Number,
                    showInWorkplan: Number,
                    howAWholeDayIsCalculated: String,
                    // Union(
                    //               Literal('workhoursPerDay'),
                    //               Literal('actualHours'),
                    //               Literal('fixedHours0'),
                    //               Literal('fixedHours7'),
                    //               Literal('fixedHours14')
                    //           ),
                    isASpendingType: Number,
                    howNetSpendingIsDerived: Union(Literal('actualSpending'), Literal('upfrontDeduction')),
                    category: Record({
                        normTimeCategoriesSNO: Number,
                        type: String,
                        name: String,
                    }),
                })
            ),
        })
    );

    const response = await requestWithType(ResponseType, getJwt(), 'norm-time-categories-get-with-types', {
        normPeriodsSNO,
    });

    return response;
}

export function createNormTimeType(
    jwt: string,
    normTimeType: INormTimeType,
    normPeriodsSNO: number
): Promise<NormTimeTypeCreateResponse> {
    const param: CreateNormTimeTypeRequest = {
        normPeriodsSNO,
        name: normTimeType.name,
        nameShort: normTimeType.nameShort,
        colorCodeBackground: normTimeType.colorCodeBackground,
        colorCodeText: normTimeType.colorCodeText,
    };
    return request(jwt, 'norm-time-types-create-other-type', param);
}

// export function createNormTimeTypePure(
//   jwt: string,
//   normPeriodsSNO: number,
//   name: string,
//   nameShort: string,
//   colorCodeBackground: string,
//   colorCodeText: string
// ): Promise<number> {
//   const args = {
//     name,
//     nameShort,
//     colorCodeBackground,
//     colorCodeText,
//     normPeriodsSNO,
//   };

//   // @TODO
//   //   - Add a RunTypes type to allow the derefencing of 'normTimeTypesSNO'
//   const response = request(jwt, 'norm-time-types-create-other-type', args);

//   const { normTimeTypesSNO } = response;

//   return normTimeTypesSNO;
// }

export async function setNormTimeTypeHowNetSpendingIsDerived(
    jwt: string,
    normTimeTypesSNO: number,
    howNetSpendingIsDerived: string
) {
    await request(jwt, 'norm-time-types-set-how-net-spending-is-derived', {
        normTimeTypesSNO,
        howNetSpendingIsDerived,
    });
}

export async function setNormTimeTypeName(jwt: string, normTimeTypesSNO: number, name: string) {
    await request(jwt, 'norm-time-types-set-name', {
        normTimeTypesSNO,
        name,
    });
}

export async function setNormTimeTypeNameShort(jwt: string, normTimeTypesSNO: number, nameShort: string) {
    await request(jwt, 'norm-time-types-set-name-short', {
        normTimeTypesSNO,
        nameShort,
    });
}

export async function setNormTimeTypeTimeUnit(jwt: string, normTimeTypesSNO: number, timeUnit: string) {
    await request(jwt, 'norm-time-types-set-time-unit', {
        normTimeTypesSNO,
        timeUnit,
    });
}

export async function fetchAllNormTimeTypes(normPeriodsSNO: number) {
    return await requestWithRuntype(NormTimeTypes, getJwt(), 'norm-time-types-get-all', { normPeriodsSNO });
}

export async function setNormTimeTypeShowInWorkplan(jwt: string, normTimeTypesSNO: number, showInWorkplan: number) {
    await request(jwt, 'norm-time-types-set-show-in-workplan', {
        normTimeTypesSNO,
        showInWorkplan,
    });
}

export async function setNormTimeTypeSetIsASpendingType(
    jwt: string,
    normTimeTypesSNO: number,
    isASpendingType: number
) {
    await request(jwt, 'norm-time-types-set-is-a-spending-type', {
        normTimeTypesSNO,
        isASpendingType,
    });
}

export async function setNormTimeTypeShowInPlanningAssistant(
    jwt: string,
    normTimeTypesSNO: number,
    showInPlanningAssistant: number
) {
    await request(jwt, 'norm-time-types-set-show-in-planning-assistant', {
        normTimeTypesSNO,
        showInPlanningAssistant,
    });
}

export async function setNormTimeTypeShowInPlanning(jwt: string, normTimeTypesSNO: number, showInPlanning: number) {
    await request(jwt, 'norm-time-types-set-show-in-planning', {
        normTimeTypesSNO,
        showInPlanning,
    });
}

export async function setNormTimeTypeIsCommon(jwt: string, normTimeTypesSNO: number, isCommon: number) {
    await request(jwt, 'norm-time-types-set-is-common', {
        normTimeTypesSNO,
        isCommon,
    });
}
export async function fetchNormTimeTypeWithSNO(normTimeTypesSNO: number) {
    return await requestWithRuntype(NormTimeType, getJwt(), 'norm-time-types-get-by-sno', {
        normTimeTypesSNO,
    });
}

export async function setNormTimeTypeMaskInOverviews(jwt: string, normTimeTypesSNO: number, maskInOverviews: number) {
    await request(jwt, 'norm-time-types-set-mask-in-overviews', {
        normTimeTypesSNO,
        maskInOverviews,
    });
}

export async function setNormTimeTypeColorCodeBackground(normTimeTypesSNO: number, colorCodeBackground: string) {
    await request(getJwt(), 'norm-time-types-set-color-code-background', {
        normTimeTypesSNO,
        colorCodeBackground,
    });
}

export async function setNormTimeTypeColorCodeText(normTimeTypesSNO: number, colorCodeText: string) {
    await request(getJwt(), 'norm-time-types-set-color-code-text', {
        normTimeTypesSNO,
        colorCodeText,
    });
}

export async function setNormTimeTypeHowAWholeDayIsCalculated(
    jwt: string,
    normTimeTypesSNO: number,
    howAWholeDayIsCalculated: string
) {
    await request(jwt, 'norm-time-types-set-how-a-whole-day-is-calculated', {
        normTimeTypesSNO,
        howAWholeDayIsCalculated,
    });
}
export async function deleteNormTimeType(jwt: string, normTimeTypesSNO: number) {
    return await request(jwt, 'norm-time-types-delete', {
        normTimeTypesSNO,
    });
}

export async function normTimeTypesOverview() {
    return await request(getJwt(), 'norm-time-types-overview', {});
}

export async function setNormTimeCategoryIsAutoApproved(normTimeCategoriesSNO: number, isAutoApproved: number) {
    return await request(getJwt(), 'norm-time-category-set-is-auto-approved', {
        normTimeCategoriesSNO,
        isAutoApproved,
    });
}
//Grupperinger
export async function createNormTimeTypeGroup(name: string, normPeriodsSNO: number) {
    return await request(getJwt(), 'norm-time-types-groups-create', { name, normPeriodsSNO });
}

export async function deleteNormTimeTypeGroup(normTimeTypesGroupsSNO: number) {
    return await request(getJwt(), 'norm-time-types-groups-delete', { normTimeTypesGroupsSNO });
}

export async function addNormTimeTypeToGroup(normTimeTypesGroupsSNO: number, normTimeTypesSNO: number) {
    return await request(getJwt(), 'norm-time-types-groups-add-time-type', {
        normTimeTypesGroupsSNO,
        normTimeTypesSNO,
    });
}

export async function removeNormTimeTypeFromGroup(normTimeTypesGroupsSNO: number, normTimeTypesSNO: number) {
    return await request(getJwt(), 'norm-time-types-groups-remove-time-type', {
        normTimeTypesGroupsSNO,
        normTimeTypesSNO,
    });
}

export async function setNormTimeTypeGroupName(name: string, normTimeTypesGroupsSNO: number) {
    return await request(getJwt(), 'norm-time-types-groups-set-name', { name, normTimeTypesGroupsSNO });
}

export async function fetchNormTimeTypeGroup(normTimeTypesGroupsSNO: number): Promise<NormTimeTypeGroupDetails> {
    return await request(getJwt(), 'norm-time-types-groups-get', { normTimeTypesGroupsSNO });
}

export async function fetchAllNormTimeTypeGroups(normPeriodsSNO: number): Promise<NormTimeTypeGroup[]> {
    return await request(getJwt(), 'norm-time-types-groups-get-all', { normPeriodsSNO });
}

export async function fetchNormTimeTypeGroupsReservationsOverview(
    normPeriodsSNO: number
): Promise<NormTimeTypeGroupReservationsOverview[]> {
    return await request(getJwt(), 'norm-time-types-groups-get-reservations-overview', { normPeriodsSNO });
}
export async function fetchNormTimeTypeGroupsReservationsNormWorker(
    normWorkersSNO: number
): Promise<NormTimeTypeGroupReservationsNormWorker> {
    return await request(getJwt(), 'norm-time-types-groups-get-overview-norm-worker', { normWorkersSNO });
}
export async function normWorkerAbsenceReservationsSetComment(
    comment: string,
    normWorkersAbsenseReservationSNO: number
) {
    return await request(getJwt(), 'norm-worker-absense-reservations-set-comment', {
        comment,
        normWorkersAbsenseReservationSNO,
    });
}
