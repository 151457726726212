import { Array, Boolean, Literal, Number, Record, Static, String, Union } from 'runtypes';
import * as st from 'simple-runtypes';

export const WholeDayCalculation = Union(Literal('workhoursPerDay'), Literal('actualHours'));

export const NormTimeTypeDTO = Record({
    normTimeTypesSNO: Number,
    type: String,
    name: String,
    nameShort: String,
    colorCodeBackground: String,
    colorCodeText: String,
    timeUnit: Union(Literal('days'), Literal('hours')),
    maskInOverviews: Number,
    showInPlanningAssistant: Number,
    showInWorkplan: Number,
    howAWholeDayIsCalculated: Union(
        Literal(''),
        Literal('workhoursPerDay'),
        Literal('actualHours'),
        Literal('fixedHours7'),
        Literal('fixedHours14')
    ),
    isASpendingType: Number,
    howNetSpendingIsDerived: Union(Literal('actualSpending'), Literal('upfrontDeduction')),
    categoryLevel: Number,
});

export const NormTimeCategoryDTO = Record({
    normTimeCategoriesSNO: Number,
    categoryName: String,
    categoryNameShort: String,
    categoryType: String,
    categoryLevel: Number,
    isAutoApproved: Boolean,
    timeTypes: Array(NormTimeTypeDTO),
});

export const NormTimeTypesDTO = Array(NormTimeCategoryDTO);

// Request
export type NormTimeTypeDTO = Static<typeof NormTimeTypeDTO>;
export type CreateNormTimeTypeRequest = Pick<
    NormTimeTypeDTO,
    'name' | 'nameShort' | 'colorCodeText' | 'colorCodeBackground'
> & {
    normPeriodsSNO: number;
};

export type DeleteNormTimeRequest = Pick<NormTimeTypeDTO, 'normTimeTypesSNO'>;

// Response
export type NormTimeTypeCreateResponse = Pick<NormTimeTypeDTO, 'normTimeTypesSNO'>;
export type NormTimeTypesDTO = Static<typeof NormTimeTypesDTO>;

// ===========================

export const NormTimeType = st.sloppyRecord({
    normTimeTypesSNO: st.number(),
    type: st.string(),
    name: st.string(),
    nameShort: st.string(),
    colorCodeBackground: st.string(),
    colorCodeText: st.string(),
    timeUnit: st.union(st.literal('hours'), st.literal('days')),
    howAWholeDayIsCalculated: st.union(
        st.literal(''),
        st.literal('workhoursPerDay'),
        st.literal('actualHours'),
        st.literal('fixedHours0'),
        st.literal('fixedHours7'),
        st.literal('fixedHours14')
    ),
    isCommon: st.number(),
    maskInOverviews: st.number(),
    showInPlanning: st.number(),
    showInPlanningWhenZero: st.number(),
    showInPlanningAssistant: st.number(),
    showInWorkplan: st.number(),
    isASpendingType: st.number(),
    howNetSpendingIsDerived: st.union(st.literal('actualSpending'), st.literal('upfrontDeduction')),
    category: st.sloppyRecord({
        normTimeCategoriesSNO: st.number(),
        type: st.string(),
        name: st.string(),
    }),
    isWorking: st.number(),
});

export type NormTimeType = ReturnType<typeof NormTimeType>;

export const NormTimeTypes = st.array(NormTimeType);

const NormTimeCount = st.sloppyRecord({
    name: st.string(),
    categorySNO: st.number(),
    count: st.number,
    normTimeSNOs: st.array(st.number()),
});

export const NormTimeCounts = st.sloppyRecord({
    countByCategory: st.array(NormTimeCount),
});
export type NormTimeCounts = ReturnType<typeof NormTimeCounts>;

export interface NormTimeTypeGroupDetails {
    normTimeTypesGroupsSNO: number;
    name: string;
    normPeriodsSNO: number;
    normTimeTypes: NormTimeType[];
}

export interface NormTimeTypeGroup {
    normTimeTypesGroupsSNO: number;
    name: string;
    normPeriodsSNO: number;
    created: string;
    normTimeTypes: NormTimeType[];
}

export interface NormTimeTypeGroupReservationsOverview {
    name: string;
    normTimeTypesGroupsSNO: number;
    normTimeTypes: NormTimeTypeGroupType[];
}
interface NormTimeTypeGroupType {
    name: string;
    normTimeTypesSNO: number;
    normWorkers: NormWorkerinGroupReservation[];
}

interface NormWorkerinGroupReservation {
    name: string;
    normWorkersSNO: number;
    reservation: number;
}

export interface NormTimeTypeGroupReservationsNormWorker {
    name: string;
    normWorkersSNO: number;
    role: string;
    normPeriod: NormPeriod;
    groups: NormTimeTypeGroupReservations[];
}
interface NormPeriod {
    start: Date;
    end: Date;
}
interface NormTimeTypeGroupReservations {
    name: string;
    normTimeTypesGroupsSNO: number;
    reservations: NormTimeTypeReservation[];
}

export interface NormTimeTypeReservation {
    normTimeTypesSNO: number;
    type: string;
    name: string;
    normWorkersAbsenseReservationsSNO: number | null;
    value: number | null;
    comment: string | null;
}
